@use "sass:math";

/**********************************
*
* VARIABLES
*
**********************************/
$black: #2c2e34;
$white: #ffffff;
$offWhite: #f5f5f5;
$gray: #eee;
$darkGray: #b6b6b6;

/**********************************
*
* MIXIN -MEDIA QUERY-
*
s**********************************/
$sm: 641px;
$md: 1025px;
$lg: 1440px;

@mixin sp {
  @media (min-width: ($sm)) {
    @content;
  }
}

@mixin tab {
  @media (min-width: ($md)) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: ($lg)) {
    @content;
  }
}

/**********************************
*
* MIXIN -FUNCTIONS-
*
**********************************/
// How to fluid() function
// $max - value on 1440 screen
// $min - value on 390 screen
// $prop - property name
// e.g.) If the font size is 40px in 1440 screen and 30px in 390 screen in design, it will be @include fluid(40, 30, font-size);
@mixin fluid(
  $max,
  $min,
  $prop,
  $scale: 1,
  $min-vw: 390px,
  $max-vw: 1440px,
  $fallback: false
) {
  $min-no-unit: strip-units($min);
  $max-no-unit: strip-units($max);

  $min-vw-no-unit: strip-units($min-vw);
  $max-vw-no-unit: strip-units($max-vw);

  $responsive: calc(
    (
        #{$min-no-unit}px + (#{$max-no-unit} - #{$min-no-unit}) *
          ((100vw - #{$min-vw}) / (#{$max-vw-no-unit} - #{$min-vw-no-unit}))
      ) * $scale
  );

  @if $fallback {
    #{$prop}: $fallback;
  } @else {
    #{$prop}: (#{$max}px);
  }

  #{$prop}: $responsive;

  @media screen and (min-width: #{$max-vw}) {
    #{$prop}: (#{$max}px);
  }
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
  // @return math.div($number, $number * 0 + 1);
}

@function vw($px-vw, $base-vw: 390px) {
  @return (($px-vw * 1px) * 100vw) / $base-vw;
  // @return math.div(($px-vw * 1px) * 100vw, $base-vw);
}

@mixin transition-common {
  transition: 0.3s ease all;
}

/**********************************
*
* COMMON
*
**********************************/
.page-width {
  width: 100%;
  // max-width: 1600px;
  max-height: 100%;
  padding-right: 10%;
  padding-left: 10%;
  margin-left: auto;
  margin-right: auto;
}

a,
button {
  @include transition-common();
}

a:hover,
button:hover {
  @include transition-common();
}

a > span {
  opacity: 1;
  @include transition-common();
}

a > span:hover {
  opacity: 0.7;
  @include transition-common();
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-800 {
  font-weight: 800;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  display: none;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0);
}

/***************FONT***************/
$sizes: (
  16: 15,
  18: 16,
  24: 19,
  31: 21,
  32: 22,
  36: 23,
  48: 36,
  50: 40,
  64: 40,
  66: 42,
  120: 72,
  133: 75,
  142: 80,
  185: 100,
);

@each $s1, $s2 in $sizes {
  .fluid-text-#{$s1}px {
    @include fluid($s1, $s2, font-size, 1);
  }

  @if ($s1 > 50) {
    @include tab {
      .fluid-text-#{$s1}px {
        @include fluid($s1, $s2, font-size, 0.85);
      }
    }
  }

  @include laptop {
    .fluid-text-#{$s1}px {
      @include fluid($s1, $s2, font-size, 1);
    }
  }

  @if ($s2 > 24) {
    @media screen and (max-width: 390px) {
      .fluid-text-#{$s1}px {
        font-size: vw($s2);
      }
    }
  }
}

/***************SCROLL BAR***************/
.scroll-bar::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 5px;
  background: $gray;
}

.scroll-bar::-webkit-scrollbar-thumb {
  display: block;
  background: $darkGray;
  border-radius: 10px;
}

/***************LOADER***************/
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/***************FADE-IN EFFECT***************/
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: ease opacity 0.4s;
}
.fade-exit {
  opacity: 1;
}

.fade-in {
  animation: fadeInAnimation 0.5s ease;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOutAnimation 0.25s ease;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/***************BUTTON***************/
/*---------STANDARD BUTTON (WHITE TEXT)--------*/
.btn-standard {
  display: inline-block;
}

.btn-standard a,
.btn-standard button {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: $white;
  border-radius: 10px;
  border: solid 1px $white;
  padding: 12px 20px;
  min-width: 200px;
  display: inline-block;
  cursor: pointer;

  @include sp {
    .btn-standard a,
    .btn-standard button {
      font-size: 16px;
      padding: 12px 30px;
    }
  }

  &:hover {
    mix-blend-mode: screen;
    color: $black;
    background-color: $white;
  }
}

/*---------STANDARD BUTTON (BLACK TEXT)--------*/
.btn-black a,
.btn-black button {
  color: $black;
  border: solid 1px $black;

  &:hover {
    mix-blend-mode: unset;
    color: $white;
    background-color: $black;
  }
}

/*---------GRADIENT BUTTON 1--------*/
.btn-effect {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 60%;
    height: 45px;
    filter: blur(2px);
    opacity: 0;
    position: absolute;
    z-index: 1;
    @include transition-common();
  }

  &:before {
    top: -3px;
    left: -3px;
  }

  &:after {
    bottom: -3px;
    right: -3px;
  }

  &.purple:before {
    background: linear-gradient(
      45deg,
      rgba(28, 59, 161, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &.orange:before {
    background: linear-gradient(45deg, #e85127 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.purple:after {
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(198, 57, 165, 1) 100%
    );
  }

  &.orange:after {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, #e9d46b 100%);
  }

  &:hover:before,
  &:hover:after {
    opacity: 1;
    @include transition-common();
  }

  a,
  button {
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    mix-blend-mode: unset !important;
    background-clip: padding-box;
    padding: 10px;
    z-index: 2;
  }

  &.purple a {
    color: $black;
    background-color: $white !important;
  }

  &.orange a {
    color: $white;
    background-color: #000 !important;
  }
}

/*---------GRADIENT BUTTON 2--------*/
.btn-effect2 {
  &:before,
  &:after {
    display: none !important;
  }

  a,
  button {
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    @include transition-common();
  }

  &:hover a,
  &.active a,
  &:hover button,
  &.active button {
    background-image: linear-gradient(
        76deg,
        #1c3ba1 1%,
        #5761b8 51%,
        #c639a5 100%
      ),
      linear-gradient(to bottom, $black, $black),
      linear-gradient(80deg, #1c3ba1 -1%, #5761b8 45%, #c639a5 98%);
    color: $white;
    @include transition-common();
  }
}

/***************SCROLL SNAP***************/
.scroll-snap-container {
  position: relative;
  height: auto;
  overflow-y: unset;
  overflow-x: hidden;
}

/*To hide the duplicate element on load made by Transition Group*/
.scroll-snap-container:nth-child(2) {
  display: none !important;
}

@media screen and (min-width: 1025px) {
  .scroll-snap-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
}

.scroll-snap-container.disable-snap {
  scroll-snap-type: unset;
  height: unset;
  overflow-y: scroll;
}

.scroll-snap-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-image 0.3s ease;
  overflow: hidden;
}

.scroll-snap-content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

.scroll-snap-content-wrapper.card-style {
  align-items: flex-start;
  padding-top: 25%;
  padding-bottom: 25%;
}

.disable-snap .scroll-snap-content-wrapper {
  align-items: flex-start;
}

@media screen and (min-width: 1025px) {
  .scroll-snap-section {
    scroll-snap-stop: always;
    scroll-snap-align: start;
    height: 100vh;
  }

  .disable-snap .scroll-snap-section {
    height: auto;
    min-height: 100vh;
  }

  .scroll-snap-content-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .disable-snap .scroll-snap-content-wrapper {
    position: relative;
  }

  .scroll-snap-content-wrapper.card-style {
    padding-top: 22vh;
    padding-bottom: unset;
  }
}

@media screen and (min-width: 1025px) and (max-height: 800px) {
  .scroll-snap-content-wrapper.card-style {
    padding-top: 20vh;
  }
}

.scroll-snap-content-wrapper > * {
  flex-basis: 100%;
}

/***************CARD STYLE FONT***************/
.card-style .fluid-text-24px {
  @media screen and (min-width: 1025px) and (max-height: 800px) {
    font-size: 2.8vh;
  }
}

.card-style .fluid-text-18px {
  @media screen and (min-width: 1025px) and (max-height: 800px) {
    font-size: 2vh;
  }
}

/***************SERVICE LIST***************/
.service-list {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100vw;
  flex-wrap: wrap;
}

.services-page .service-list {
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: 0.5s ease border-bottom;
}

.service-list .scroll-snap-horizontal-item {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  width: 100%;
}

.service-list .scroll-snap-horizontal-item:last-child {
  border-right: none;
}

.service-list .scroll-snap-horizontal-item a {
  width: 100%;
}

.services-page .service-list .scroll-snap-horizontal-item {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.service-list .scroll-snap-item-text-container {
  padding-top: 15%;
  padding-left: 15%;
  padding-right: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-list .scroll-snap-item-text-container .text-outline-effect {
  opacity: 0.2;
}

/***************SCROLL SNAP (HORIZONTAL)***************/
.scroll-snap-horizontal-container {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;

  @include tab {
    scroll-snap-type: none;
    overflow-x: auto;
    overflow-y: auto;
  }
}

.scroll-snap-horizontal-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0);
}

.scroll-snap-horizontal-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
}

​ .scroll-snap-horizontal-container .scroll-snap-horizontal-item {
  scroll-snap-align: start;
}

.scroll-snap-item-image-container .scroll-snap-item-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}

/*half-screen*/
.half-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
  /* min-width: 90vw; */
  min-width: 100vw;
}

.half-screen.scroll-snap-horizontal-container .scroll-snap-item-text-container {
  align-items: flex-start;
  height: auto;
  min-height: 27.5vh;
}

.half-screen.scroll-snap-horizontal-container
  .scroll-snap-item-image-container {
  height: 27.5vh;
}

@include sp {
  .half-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
    min-width: 50vw;
  }
}

@include tab {
  .half-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
    min-width: 25vw;
  }

  .half-screen.scroll-snap-horizontal-container
    .scroll-snap-item-text-container {
    min-height: unset;
    height: 30vh;
  }

  .half-screen.scroll-snap-horizontal-container
    .scroll-snap-item-image-container {
    min-height: unset;
    height: 30vh;
  }
}

/*full-screen*/
.full-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
  min-width: 100vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.full-screen.scroll-snap-horizontal-container .scroll-snap-item-text-container {
  height: 70vh;
}

.full-screen.scroll-snap-horizontal-container
  .scroll-snap-item-image-container {
  height: 30vh;
}

.full-screen.scroll-snap-horizontal-container
  .scroll-snap-item-text-container
  .text-outline-effect {
  width: 100% !important;
}

@include sp {
  .full-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
    min-width: 50vw;
  }
}

@include tab {
  .full-screen.scroll-snap-horizontal-container .scroll-snap-horizontal-item {
    min-width: 25vw;
    border-bottom: none;
  }

  .full-screen.scroll-snap-horizontal-container
    .scroll-snap-item-text-container {
    min-height: unset;
    height: 70vh;
  }

  .full-screen.scroll-snap-horizontal-container
    .scroll-snap-item-image-container {
    min-height: unset;
    height: 30vh;
  }
}

.slider-button {
  display: block;
}

.slider-button:before {
  position: relative;
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1em;
  border-right: 0.2em solid #e8e8e8;
  border-top: 0.2em solid #e8e8e8;
}

.slider-button.prev-button:before {
  transform: rotate(-135deg);
}

.slider-button.next-button:before {
  transform: rotate(45deg);
}

/***************MAIN HEADING***************/
.large-heading-outline-title {
  word-spacing: 100vw;
  @media screen and (min-width: 1025px) and (max-height: 900px) {
    font-size: 20.55vh;
  }

  @include tab {
    word-spacing: unset;
  }
}

.large-heading-title {
  word-spacing: 100vw;

  @media screen and (min-width: 1025px) and (max-height: 900px) {
    font-size: 13.33vh;
  }

  @media screen and (min-width: 1920px) {
    width: 60% !important;
  }

  @include sp {
    word-spacing: unset;
  }
}

/***************TEXT OUTLINE EFFECT***************/
.text-outline-effect {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.35);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  font-family: "Open Sans", sans-serif;
}

.text-outline-effect-gradient {
  -webkit-text-stroke: 1px transparent;
  font-family: "Open Sans", sans-serif;

  &.purple {
    color: #ffffff;
    background: -webkit-linear-gradient(
      90deg,
      rgba(28, 59, 161, 1) 0%,
      rgba(87, 97, 184, 1) 50%,
      rgba(198, 57, 165, 1) 100%
    );
    -webkit-background-clip: text;
  }

  &.orange {
    color: #000;
    background: -webkit-linear-gradient(
      90deg,
      #e85127 0%,
      #f1a06a 50%,
      #e9d46b 100%
    );
    -webkit-background-clip: text;
  }
}

/***************SCROLL ICON***************/
.scroll-icon {
  &:before {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}

/***************GRADIENT STYLE***************/
.gradient-text-type1 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(
      88deg,
      #1c3ba1 0%,
      #5761b8 47%,
      #c639a5 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #1c3ba1;
  -webkit-box-decoration-break: clone;
  display: inline;
}

.gradient-bg-type1 {
  background-image: linear-gradient(
      88deg,
      #1c3ba1 0%,
      #5761b8 47%,
      #c639a5 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #1c3ba1;
}

.gradient-text-type2 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(
      88deg,
      #28c2e5 0%,
      #00a679 47%,
      #f4e271 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #28c2e5;
  -webkit-box-decoration-break: clone;
  display: inline;
}

.gradient-bg-type2 {
  background-image: linear-gradient(
      88deg,
      #28c2e5 0%,
      #00a679 47%,
      #f4e271 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #28c2e5;
}

.gradient-text-type3 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-image: -webkit-linear-gradient(
      90deg,
      #e85127 0%,
      #f1a06a 50%,
      #e9d46b 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #e85127;
  -webkit-box-decoration-break: clone;
  display: inline;
}

.gradient-bg-type3 {
  background: -webkit-linear-gradient(
      90deg,
      #e85127 0%,
      #f1a06a 50%,
      #e9d46b 100%
    ),
    linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-color: #e85127;
}

/***************FEATURED PROJECTS***************/
.featured-projects {
  margin-left: 0;

  @include sp {
    margin-left: -3%;
  }
}
.featured-projects .thumbnail {
  width: 100%;
  height: 25vh;

  @media screen and (min-width: 480px) {
    height: 200px;
  }

  @include sp {
    height: 13vh;
    max-height: 170px;
  }

  @include tab {
    aspect-ratio: 1/1.35;
    height: 100%;
    max-height: 100%;
  }

  .shimmer {
    width: 100%;
    height: 100%;
    display: inline-block;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
      100%;
    background-repeat: no-repeat;
    animation: shimmer 3s infinite;
    max-width: 100%;
    background-color: #dfdfdf;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  .load-error-msg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    background-color: #cccccc;
    color: #6b6b6b;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/***************CUSTOM STYLES***************/
/*--------HEADER--------*/
#header {
  position: fixed;
  top: 0;
  left: 0;

  &.dark-style .nav-button-wrapper {
    border-color: $black;
    @include transition-common();
  }

  &.dark-style .nav-button span {
    background: $black;
    @include transition-common();
  }

  .nav-button {
    position: relative;
    width: 25px;
    height: 20px;
    padding: 10px;

    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      @include transition-common();
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }

    span:nth-child(4) {
      top: 18px;
    }
  }

  .open .nav-button {
    span {
      @include transition-common();
    }

    span:nth-child(1) {
      top: 9px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 9px;
      width: 0%;
      left: 50%;
    }
  }

  .menu {
    transform: translateY(0);
    @include transition-common();
  }
  .menu.hide {
    transform: translateY(-100%);
    @include transition-common();
  }

  a {
    color: $white;
    font-size: 16px;
  }

  a.active {
    cursor: default;
    pointer-events: none;
  }

  .navigation-list-item-border {
    background-color: $white;
    width: 100%;
    height: 2px;
    display: block;
    position: relative;
    bottom: -17px;
    opacity: 0;
    @include transition-common();
  }

  a:hover + .navigation-list-item-border,
  a.active + .navigation-list-item-border {
    opacity: 1;
    @include transition-common();
  }

  &.dark-style .blur-effect:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.25);
    width: 100vw;
    margin-left: calc(-100vw - 15%);
    transition: all 0.5s ease-in-out;
  }

  .blur-effect:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.25);
    width: 100vw;
    margin-left: -15%;
    transition: all 0.5s ease-in-out;
  }

  &.dark-style .blur-effect:after {
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.5s ease-in-out;
  }

  .sitelogo-image {
    background-color: #fff;
    transition: all 0.5s ease-in-out;
  }

  &.dark-style .sitelogo-image {
    background-color: #000;
    transition: all 0.5s ease-in-out;
  }
  .white-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $white;
    margin-left: 3%;
    margin-right: 3%;
    z-index: 2;
    transition: all 0.5s ease-in-out;
  }

  &.dark-style .white-line {
    background-color: $black;
    transition: all 0.5s ease-in-out;
  }

  &.mobile-nav-menu {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .hamburger-image {
    cursor: pointer;
    @include transition-common();
  }

  .hamburger-menu-list {
    width: 100vw;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    transform: translateX(100%);
    @include transition-common();
  }

  .hamburger-menu-list.active {
    transform: translateX(0);
    @include transition-common();
  }

  .hamburger-menu-list ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
  }

  .hamburger-menu-list ul li {
    width: 100%;
  }

  .hamburger-menu-list a {
    color: $black;
    width: 100%;
    display: block;
    font-size: 5vw;
    padding-top: 6vw;
    padding-bottom: 6vw;
    position: relative;

    @media screen and (min-width: 480px) {
      font-size: 20px;
      padding-top: 4vh;
      padding-bottom: 4vh;
    }

    &.active {
      opacity: 0.7;
    }
  }
}

/*--------FOOTER--------*/
#footer {
  .scroll-snap-content-wrapper {
    padding-top: 10%;
    padding-bottom: 0;
    align-items: center;

    @include tab {
      padding-top: 3%;
      padding-bottom: 0;
    }

    @media screen and (max-width: 1024px) {
      min-height: auto;
    }
  }

  .contact-email {
    opacity: 1;
    @include transition-common();

    @media screen and (max-width: 480px) {
      font-size: 6vw;
    }
  }

  .contact-email:hover {
    opacity: 0.7;
    @include transition-common();
  }

  @media screen and (min-width: 1025px) and (max-height: 900px) {
    .footer-title {
      white-space: nowrap;
    }
  }

  @media screen and (min-width: 1025px) and (max-height: 850px) {
    .footer-title {
      white-space: nowrap;
      margin-bottom: 3.15vw;
      font-size: 3.1vw;
    }

    .footer-heading {
      margin-bottom: 5.6px;
      font-size: 13px;
    }

    .contact {
      margin-bottom: 4.6vw;
    }

    .office-item {
      margin-bottom: 2.18vw;
    }

    .office-address1 {
      font-size: 1.3vw;
    }

    .office-phone,
    .office-address2 {
      font-size: 1vw;
    }
  }

  a.active span {
    opacity: 0.7;
    cursor: default;
  }
}

/*--------HOME PAGE--------*/
/*services section*/
.home-page #services {
  .service-list .text-outline-effect {
    display: none;
  }
}

/*letschat section*/
$num_of_client_logos: 13;
$marquee_item_width_mobile: 33.333vw;
$marquee_item_width_desktop: 20vw;

.home-page #letschat {
  .scroll-snap-content-wrapper {
    padding-bottom: 0;
  }

  .large-heading-outline-title {
    @include tab {
      white-space: nowrap;
    }
  }

  .marquee-wrapper {
    width: calc($num_of_client_logos * $marquee_item_width_mobile * 2);

    @include sp {
      width: calc($num_of_client_logos * $marquee_item_width_desktop * 2);
    }
  }

  .marquee1 {
    transform: translateX(0);
    animation: marquee1-mobile 20s linear infinite;

    @include sp {
      animation: marquee1 40s linear infinite;
    }
  }

  .marquee2 {
    transform: translateX(0);
    animation: marquee2-mobile 20s linear infinite;

    @include sp {
      animation: marquee2 40s linear infinite;
    }
  }

  .marquee-item {
    width: $marquee_item_width_mobile;

    @include sp {
      width: $marquee_item_width_desktop;
    }
  }
}

@keyframes marquee1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc($marquee_item_width_desktop * $num_of_client_logos * (-1))
    );
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc($marquee_item_width_desktop * $num_of_client_logos * (-1))
    );
  }
}

@keyframes marquee1-mobile {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc($marquee_item_width_mobile * $num_of_client_logos * (-1))
    );
  }
}

@keyframes marquee2-mobile {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc($marquee_item_width_mobile * $num_of_client_logos * (-1))
    );
  }
}

/*projects section*/
.home-page #projects {
  .grid {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    display: grid;
    grid-template-columns: repeat(50, 2%);
    grid-template-rows: repeat(50, 2%);
    overflow: hidden;
  }

  .grid-item-image {
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    position: relative;
    opacity: 0.75;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
  }
}

/*---------COMPANY PAGE--------*/
/*our story section*/
.company-page #ourstory {
  .scroll-snap-content-wrapper.card-style {
    padding-top: 20vh;

    @media screen and (min-width: 1025px) and (max-height: 900px) {
      padding-top: 18vh;
    }
  }

  .large-heading-outline-title,
  .large-heading-title {
    @include tab {
      white-space: nowrap;
    }
  }
}

/*our story section on company page for ALD site*/
.ald-website .company-page #ourstory .scroll-snap-content-wrapper.card-style {
  @include tab {
    padding-top: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.company-page #overview {
  .features-list .features-list-item .number {
    @media screen and (min-width: 1025px) and (max-height: 900px) {
      font-size: 7.11vh;
    }
  }
}

/*offices section*/
.office-location {
  @media screen and (max-width: 480px) {
    font-size: 4.5vw !important;
  }
}

/*awards section*/
.company-page {
  .awards-container {
    @media screen and (min-width: 1025px) and (max-height: 800px) {
      height: 55vh !important;
    }
  }

  .award-item * {
    transition: 0.8s ease all;
  }

  .award-item:hover * {
    transition: 0.8s ease all;
  }

  .award-title {
    position: relative;
    font-family: "Open Sans", sans-serif;
    opacity: 1;
    white-space: pre-line;

    @media screen and (max-width: 1024px) {
      opacity: 1 !important;
    }
  }

  .award-item.active .award-title,
  .award-item:hover .award-title {
    opacity: 0;
  }

  .award-title-outline-text {
    white-space: pre-line;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-text-stroke: 1px $black;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    opacity: 0;

    @media screen and (max-width: 1024px) {
      opacity: 0 !important;
    }
  }

  .award-item.active .award-title-outline-text,
  .award-item:hover .award-title-outline-text {
    opacity: 1;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);

    &.light {
      -webkit-text-stroke: 1px $black;
    }

    &.dark {
      -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
    }
  }

  .award-year {
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0) !important;
    &.purple {
      background-image: linear-gradient(
          64deg,
          #1c3ba1 -6%,
          #5761b8 37%,
          #c639a5 87%
        ),
        linear-gradient(to bottom, $black, $black) !important;
    }

    &.orange {
      background-image: linear-gradient(
          64deg,
          #e85127 -6%,
          #f1a06a 37%,
          #e9d46b 87%
        ),
        linear-gradient(to bottom, $black, $black) !important;
    }

    opacity: 0;

    @media screen and (max-width: 1024px) {
      opacity: 1 !important;
    }
  }

  .award-item.active .award-year,
  .award-item:hover .award-year {
    opacity: 1;
  }

  .awards-container .image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transform: translateY(50%);
    transition: 0.8s ease all;
  }

  .awards-container .image:first-child {
    position: relative;
  }

  .awards-container .image.active {
    opacity: 1;
    transform: translateY(0);
    z-index: 2;
    transition: 0.8s ease all;
  }
}

/*---------SERVICE SINGLE PAGE--------*/
.service-single.fade-enter {
  opacity: 1 !important;
}

.service-single .content.fade-in-enter {
  animation: fade-in-animation 0.3s ease-out;
  animation-fill-mode: forwards;
}

.service-single.fade-in {
  opacity: 0;
  animation: fade-in-animation 0.4s ease-out;
  animation-fill-mode: forwards;
}

.service-single .content {
  opacity: 1;
}

.service-single .content.fade-in {
  opacity: 1;
  transition: 0.2s ease all;
}

.service-single .content.fade-out {
  opacity: 0;
  transition: 0.2s ease all;
}

.service-single .small-heading .normal-text {
  opacity: 0.4;
  @include transition-common();
}

.service-single .small-heading .normal-text:hover,
.service-single .active .small-heading .normal-text {
  opacity: 1;
  @include transition-common();
}

.service-single .scroll-snap-section,
.service-single .scroll-snap-content-wrapper {
  min-height: auto !important;
  height: auto !important;
}

.service-single .animation .service-list {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  transition: 0.5s ease border-bottom;
}

.service-single .animation .scroll-snap-horizontal-item {
  height: 100vh;
  animation: hight-animation1 0.5s ease-out;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.service-single .no-animation .scroll-snap-horizontal-item {
  height: 140px !important;
  padding-top: 60px !important;
}

.service-single .animation .scroll-snap-item-text-container {
  height: auto;
  animation: hight-animation2 0.5s ease-out;
  animation-fill-mode: forwards;
}

.service-single .no-animation .scroll-snap-item-text-container {
  height: 80px !important;
  padding-top: 0 !important;
}

.service-single .animation .description,
.service-single .animation .learn-more {
  animation: hight-animation3 0.5s ease-out;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.service-single .no-animation .description,
.service-single .no-animation .learn-more {
  opacity: 0 !important;
  max-height: 0 !important;
  overflow: hidden !important;
}

.service-single .animation .text-outline-effect {
  animation: fade-out-animation-text 0.5s ease-out;
  animation-fill-mode: forwards;
}

.service-single .no-animation .text-outline-effect {
  opacity: 0 !important;
}

.service-single .animation .scroll-snap-item-image-container {
  animation: fade-out-animation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.service-single .no-animation .scroll-snap-item-image-container {
  opacity: 0 !important;
}

.service-single .animation .slider-button {
  animation: hight-animation4 0.5s ease-out;
  animation-fill-mode: forwards;
}

.service-single .no-animation .slider-button {
  transform: translateY(100%) !important;
}

@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out-animation-text {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hight-animation1 {
  0% {
    height: 100vh;
  }

  100% {
    height: 140px;
    padding-top: 60px;
  }
}

@keyframes hight-animation2 {
  0% {
    height: 70vh;
  }

  100% {
    height: 80px;
    padding-top: 0;
  }
}

@keyframes hight-animation3 {
  0% {
    opacity: 1;
    max-height: 300px;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

@keyframes hight-animation4 {
  100% {
    transform: translateY(100%);
  }
}

.other-projects .btn-standard a {
  min-width: 135px;
  white-space: nowrap;
}

/*---------PROJECT SINGLE PAGE--------*/
.swiper-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.work-single .gallery .swiper-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.work-single .gallery .swiper-container .swiper-wrapper {
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.work-single .gallery .swiper-container .swiper-slide {
  position: relative;
  height: auto;
  opacity: 0.3;
  transform: scale(0.85);
  transition: all 0.3s ease;

  &.swiper-slide-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease;
  }
}

.work-single .gallery .swiper-container .swiper-slide img {
  max-height: 80vh;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.work-single .gallery .swiper-container .swiper-pagination {
  bottom: 0;
}

.work-single
  .gallery
  .swiper-container
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: $white;
  opacity: 1;
  border: 2px solid $black;
  transition: 0.3s ease;
}

.work-single
  .gallery
  .swiper-container
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #8d4dae;
  background-image: linear-gradient(
      45deg,
      #1c3ba1 1%,
      #5761b8 51%,
      #c639a5 100%
    ),
    linear-gradient(to bottom, #424b5a, #424b5a);
  transition: 0.3s ease;
}

.work-single
  .gallery.dark
  .swiper-container
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #e9b26b;
  background-image: linear-gradient(
      45deg,
      #e85127 1%,
      #f1a06a 51%,
      #e9d46b 100%
    ),
    linear-gradient(to bottom, #424b5a, #424b5a);
  transition: 0.3s ease;
}

.work-single .gallery .swiper-container {
  .swiper-button-next,
  .swiper-button-prev {
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
  }
}

.work-single .gallery.light .swiper-container {
  .swiper-button-next,
  .swiper-button-prev {
    box-shadow: 2px 2px 2px #00000073;
    color: rgba(176, 176, 176, 0.8);
    background-color: #fcfcfc;
  }
}

.work-single .gallery.dark .swiper-container {
  .swiper-button-next,
  .swiper-button-prev {
    box-shadow: 2px 2px 2px #ffffff73;
    color: rgba(255, 255, 255, 0.8);
    background-color: #000000;
  }
}

.work-single .gallery .swiper-container .swiper-button-next:after,
.work-single .gallery .swiper-container .swiper-button-prev:after {
  font-size: 4.5vw;

  @include sp {
    font-size: 20px;
  }
}

.work-single .gallery .swiper-container .swiper-button-next {
  right: 3vw;
}

@include sp {
  .work-single .gallery .swiper-container .swiper-button-next {
    right: 20px;
  }
}

.work-single .gallery .swiper-container .swiper-button-prev {
  left: 3vw;
}

@include sp {
  .work-single .gallery .swiper-container .swiper-button-prev {
    left: 20px;
  }
}

/*---------PROJECTS PAGE--------*/
.work-page {
  .scroll-snap-content-wrapper {
    align-items: flex-start;

    @include tab {
      align-items: center;
    }
  }

  .left-column {
    @include tab {
      height: calc(100vh - 240px - 10vh);
      overflow-y: auto;
    }
  }

  .right-column {
    height: calc(70vh);
    overflow-y: auto;
    @include sp {
      height: calc(50vh);
    }

    @include tab {
      height: calc(100vh - 240px - 10vh);
    }
  }

  .input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }

  .input:checked + .checkbox.purple {
    border-color: #8d4dae;
    background-image: linear-gradient(
        45deg,
        #1c3ba1 1%,
        #5761b8 51%,
        #c639a5 100%
      ),
      linear-gradient(to bottom, #424b5a, #424b5a);
    transition: 0.3s ease;
  }

  .input:checked + .checkbox.orange {
    border-color: #e9b26b;
    background-image: linear-gradient(
        45deg,
        #e85127 1%,
        #f1a06a 51%,
        #e9d46b 100%
      ),
      linear-gradient(to bottom, #424b5a, #424b5a);
    transition: 0.3s ease;
  }

  .toggle-content {
    @media screen and (max-width: 1024px) {
      display: block !important;
    }
  }

  .toggle-btn {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    @include transition-common();

    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  .toggle-btn.close {
    transform: rotate(-135deg);
    @include transition-common();
  }
}

/*---------TEAM PAGE--------*/
.team-page {
  .sort .btn-effect2 a,
  .sort .btn-effect2 button {
    font-size: 12px;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

$admin1: $black;
$admin2: #f2f2f2;
$finance1: #3275b5;
$finance2: #d1ebff;
$marketing1: #8c275c;
$marketing2: #edc2f3;
$quotations1: #c86627;
$quotations2: #f1e2b9;
$integration1: #71160b;
$integration2: #fbcfcd;
$interactive1: #265b5a;
$interactive2: #c1ece7;
$businessdev1: #5c1946;
$businessdev2: #eac8da;
$support1: #008253;
$support2: #bfe0d4;
$leadership1: #273499;
$leadership2: #c9cce5;
$design1: #1f4d14;
$design2: #cfefb0;
$abernathydesign1: #bcffd1;
$abernathydesign2: #defbe7;
$default: #cbcbcb;

$departments: (
  "admin1": $admin1,
  "admin2": $admin2,
  "finance1": $finance1,
  "finance2": $finance2,
  "marketing1": $marketing1,
  "marketing2": $marketing2,
  "quotations1": $quotations1,
  "quotations2": $quotations2,
  "integration1": $integration1,
  "integration2": $integration2,
  "interactive1": $interactive1,
  "interactive2": $interactive2,
  "businessdev1": $businessdev1,
  "businessdev2": $businessdev2,
  "support1": $support1,
  "support2": $support2,
  "leadership1": $leadership1,
  "leadership2": $leadership2,
  "design1": $design1,
  "design2": $design2,
  "abernathydesign1": $abernathydesign1,
  "abernathydesign2": $abernathydesign2,
  "default": $default,
);

@each $d, $c in $departments {
  .text-#{$d} {
    color: $c;
  }

  .border-#{$d} {
    border-color: $c;
  }

  .department-#{$d}:hover {
    background-color: $c;
  }

  .bg-#{$d} {
    background-color: $c;
  }
}

// FOR ALS SITE
.border-orange {
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #e85127, #e9d46b);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/*---------CONTACT PAGE--------*/
.contact-page .text-outline-effect {
  white-space: nowrap;
}

.contact-form {
  .form-field-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;

    @include tab {
      flex-wrap: nowrap;
      margin-top: 0;
      margin-bottom: 18px;
    }
  }

  .form-label,
  .dot {
    color: $white;
    font-weight: 500;
    white-space: nowrap;
  }

  .form-input {
    display: block;
    width: 100%;
    outline: none;
    background-color: unset;
    border-bottom: 1px solid $white;
    color: $white;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    border-radius: 0;
    -webkit-appearance: none;

    @include tab {
      font-size: 16px;
    }
  }

  .form-input::-webkit-input-placeholder {
    color: $white;
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
  }

  .form-input:-moz-placeholder {
    color: $white;
  }

  .form-input::-moz-placeholder {
    color: $white;
  }

  .form-input:-ms-input-placeholder {
    color: $white;
  }

  .form-input:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  select.form-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 6px;
  }

  .form-input.department {
    width: calc(100% - 10px);

    @include tab {
      width: 40%;
    }
  }

  .form-input.email {
    width: calc(100% - 10px);

    @include tab {
      width: 100%;
    }
  }

  .form-input.message {
    text-align: left;
  }

  .dot {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;

    @include tab {
      position: relative;
      right: unset;
      bottom: unset;
      margin-left: 10px;
    }
  }
}
